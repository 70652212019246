<template>
  <div :class="!onlyTime ? 'grid-1fr-1fr' : ''">
    <scroll-picker
      v-if="!onlyTime"
      :options="dates"
      :drag-sensitivity="0.8"
      :touch-sensitivity="0.8"
      :value="dateSelected"
      @input="handleDateInput"
    />
    <div class="flex-row">
      <scroll-picker
        :options="hours"
        class="flex-row justify-end vue-scroll-picker-numeric"
        :drag-sensitivity="0.8"
        :touch-sensitivity="0.8"
        :value="hourSelected"
        @input="handleHourInput"
      />
      <scroll-picker
        :options="minutes"
        class="flex-row justify-start vue-scroll-picker-numeric"
        :drag-sensitivity="0.8"
        :touch-sensitivity="0.8"
        :value="minuteSelected"
        @input="handleMinuteInput"
      />
    </div>
  </div>
</template>

<script>
import { ScrollPicker } from 'vue-scroll-picker';
import { addDays, eachDayOfInterval, format, isToday, addMinutes, parse, set, isSameDay } from 'date-fns';

export default {
  name: 'DatetimeWheel',
  components: {
    ScrollPicker,
  },
  props: {
    timeSelected: {
      type: Date,
      default: null,
    },
    onlyTime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerDateSelected: null,
      innerHourSelected: null,
      innerMinuteSelected: null,
    };
  },
  computed: {
    selectedDateTime() {
      return set(this.innerDateSelected, {
        hours: this.hourSelected,
        minutes: this.minuteSelected,
        seconds: 0,
        milliseconds: 0,
      });
    },
    earliestAvailableDateTime() {
      const minimumDateTime = this.onlyTime
        ? set(new Date(), {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          })
        : new Date();

      const earliestAvailableDateTime = set(minimumDateTime, {
        minutes: Math.ceil(minimumDateTime.getMinutes() / 5) * 5,
        seconds: 0,
        milliseconds: 0,
      });

      return earliestAvailableDateTime >= minimumDateTime
        ? earliestAvailableDateTime
        : addMinutes(earliestAvailableDateTime, 5);
    },
    isSelectedDateTimeValid() {
      return this.selectedDateTime >= this.earliestAvailableDateTime;
    },
    dates() {
      return eachDayOfInterval({
        start: this.earliestAvailableDateTime,
        end: addDays(this.earliestAvailableDateTime, 90),
      }).map((date) => ({
        value: format(date, 'yyyy-MM-dd'),
        name: isToday(date)
          ? this.$t('datetime.today')
          : format(date, 'ccc d LLL'),
      }));
    },
    hours() {
      // trigger recomputation for infinite scroll
      this.innerHourSelected;

      const isEarliestDateSelected = isSameDay(this.innerDateSelected, this.earliestAvailableDateTime);
      const earliestHour = isEarliestDateSelected ? this.earliestAvailableDateTime.getHours() : 0;

      return Array
        .from({ length: 24 * 3 }, (_, i) => ({
          value: (i - 24).toString(),
          name: (i % 24).toString().padStart(2, '0'),
        }))
        .filter(({ value }) => {
          if (!isEarliestDateSelected) {
            return true;
          }

          const hour = parseInt(value);

          return hour >= earliestHour && hour < 24;
        });
    },
    minutes() {
      // trigger recomputation for infinite scroll
      this.innerMinuteSelected;

      const isEarliestHourSelected = isSameDay(this.innerDateSelected, this.earliestAvailableDateTime)
        && this.hourSelected === this.earliestAvailableDateTime.getHours();

      const earliestMinute = isEarliestHourSelected ? this.earliestAvailableDateTime.getMinutes() : 0;

      return Array
        .from({ length: 12 * 3 }, (_, i) => ({
          value: (i * 5 - 60).toString(),
          name: (i * 5 % 60).toString().padStart(2, '0'),
        }))
        .filter(({ value }) => {
          if (!isEarliestHourSelected) {
            return true;
          }

          const minute = parseInt(value);

          return minute >= earliestMinute && minute < 60;
        });
    },
    dateSelected() {
      return format(
          this.innerDateSelected ?? this.earliestAvailableDateTime,
          'yyyy-MM-dd'
      );
    },
    hourSelected() {
      return (parseInt(this.innerHourSelected ?? 0) + 24) % 24;
    },
    minuteSelected() {
      const minute = (parseInt(this.innerMinuteSelected ?? 0) + 60) % 60;

      return Math.ceil(minute / 5) * 5 % 60;
    },
  },
  watch: {
    timeSelected: {
      immediate: true,
      handler: 'setDateTime',
    },
  },
  methods: {
    setDateTime(dateTime = null) {
      if (!dateTime || dateTime < this.earliestAvailableDateTime) {
        dateTime = this.earliestAvailableDateTime;
      }

      this.innerDateSelected = dateTime;
      this.innerHourSelected = dateTime.getHours();
      this.innerMinuteSelected = dateTime.getMinutes();

      this.emitDatetimeChange();
    },
    restrictToValidDateTime(unit = null) {
      if (this.isSelectedDateTimeValid) {
        return;
      }

      if (unit === 'date') {
        this.innerDateSelected = this.earliestAvailableDateTime;
      }

      if (unit === 'hour') {
        this.innerHourSelected = this.earliestAvailableDateTime.getHours();
      }

      if (unit === 'minute') {
        this.innerMinuteSelected = this.earliestAvailableDateTime.getMinutes();
      }

      if (!this.isSelectedDateTimeValid) {
          this.setDateTime(this.earliestAvailableDateTime);
        }
    },
    onInputChange(unit, value) {
      switch(unit) {
        case 'date':
          this.innerDateSelected = parse(value, 'yyyy-MM-dd', this.earliestAvailableDateTime);
          break;
        case 'hour':
          this.innerHourSelected = value;
          break;
        case 'minute':
          this.innerMinuteSelected = value;
          break;
      }

      if (!this.isSelectedDateTimeValid) {
        this.$nextTick(() => this.restrictToValidDateTime(unit));
        return;
      }

      this.emitDatetimeChange();
    },
    handleDateInput(date) {
      this.onInputChange('date', date);
    },
    handleHourInput(hour) {
      this.onInputChange('hour', hour);
    },
    handleMinuteInput(minute) {
      this.onInputChange('minute', minute);
    },
    emitDatetimeChange() {
      this.$emit('datetimeChange', this.selectedDateTime);
    },
  },
};
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.grid-1fr-1fr {
  width: 100%;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
}

.vue-scroll-picker >>> .middle {
  z-index: 0;
  background-color: #e3e1de;
}

.vue-scroll-picker >>> .top {
  border-bottom: none;
  z-index: 2;
}

.vue-scroll-picker >>> .bottom {
  border-top: none;
  z-index: 2;
}

>>> .vue-scroll-picker-list-rotator {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.75rem;
  flex-shrink: 0;
}

>>> .vue-scroll-picker-list {
  z-index: 1;
  height: 100%;
}

.vue-scroll-picker-numeric >>> .vue-scroll-picker-list {
  position: relative;
  width: 3rem;
}
</style>
