<script>
import { mapState } from 'vuex';
import { namespace as userNamespace } from '@/store/modules/user-types';
import { countryToEId } from '@/vendor/utils/eId';
import { EventBus } from '@/vendor/events';
import AvatarWithIcon from '@/components/shared/account/avatarWithIcon.vue';
import Icon from '@/components/core/typography/icon.vue';

export default {
  computed: {
    ...mapState(userNamespace, ['profile']),
    eidName() {
      const eid = countryToEId(this.profile.country);
      return eid.name;
    }
  },
  methods: {
    showSuccess() {
      this.$modal.show('dialog', {
        icon: {
          component: AvatarWithIcon,
          props: {
            avatarSrc: this.profile.image,
            iconName: 'avatar-checkmark',
            iconProps: {
              color: 'accent',
              size: 'md',
            },
          },
        },
        title: [
          {
            text: this.$t('profile.eIdVerificationSuccess.title.main'),
            color: '#093527',
          },
          {
            text: this.$t('profile.eIdVerificationSuccess.title.secondary', {
              eId: this.eidName 
            }),
            color: '#00CC6E',
          },
        ],
        text: this.$t('profile.eIdVerificationSuccess.text'),
        textPreset: 'sub',
        success: {
          text: this.$t('profile.eIdVerificationSuccess.buttonText'),
        },
        color: 'success',
        cancel: false,
        closeButton: true,
      });
    },

    showFailure() {
      this.$modal.show('dialog', {
        icon: {
          component: Icon,
          props: {
            name: 'paper-plane',
            color: 'white',
            size: 'xxl',
          },
        },
        title: [
          {
            text: this.$t('profile.eIdVerificationFailed.title.main'),
            color: '#093527',
          },
          {
            text: this.$t('profile.eIdVerificationFailed.title.secondary', {
              eId: this.eidName,
            }),
            color: '#FF2360',
          },
        ],
        text: this.$t('profile.eIdVerificationFailed.text', { eId: this.eidName, }),
        textPreset: 'sub',
        success: {
          text: this.$t('profile.eIdVerificationFailed.buttonText'),
          handler: () => {
            EventBus.$emit('eid-verification-sheet:open');
          },
          color: 'error-light',
        },
        color: 'success',
        cancel: false,
        closeButton: true,
      });
    }
  },
  render() {
    return this.$slots.default;
  },
};
</script>
