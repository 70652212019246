<template>
  <n-bottom-sheet
    v-if="hasEidFeature"
    ref="sheet"
    type="header"
    :title="$t('eidVerificationSheet.title', { provider: eidProvider?.name })"
    class="sheet"
    :title-uppercase="false"
    :hex-color="eidProvider?.sheetBackgroundColor"
    dismissible
    @dismissed="$emit('dismissed')"
  >
    <n-grid
      v-if="loaded"
      :row-gap="8"
      :top-gap="6"
      :bottom-gap="0"
    >
      <n-column :span="6">
        <svg-icon
          class="eid-logo"
          :icon="eidProvider?.logo"
        />
      </n-column>

      <n-column :span="6">
        <n-grid>
          <n-text-model
            size="lg"
            align="center"
            weight="medium"
            class="span-6"
          >
            {{ $t('eidVerificationSheet.heading', { provider: eidProvider?.name }) }}
          </n-text-model>

          <n-text-model
            size="sm"
            align="center"
            class="span-6"
          >
            {{ $t('eidVerificationSheet.description', { provider: eidProvider?.name }) }}
          </n-text-model>
        </n-grid>
      </n-column>

      <n-column
        :span="6" 
        center
      >
        <n-grid :row-gap="2">
          <div class="span-6 text-center">
            <eid-provider-button 
              :user-language="userLanguage"
              :e-id="eidProviderKey"
              @click="initiateVerification"
            />
          </div>
          
          <n-button
            block
            class="span-6"
            color="neutral-muted"
            size="lg"
            @click="dismiss"
          >
            {{ $t('eidVerificationSheet.dismiss') }}
          </n-button>
        </n-grid>
      </n-column>
    </n-grid>
  </n-bottom-sheet>
</template>

<script>
import store from '@/store';
import axios from '@/vendor/axios';
import config from '@shared/config.json';
import { send } from '@/native-bridge';
import { EventBus } from '@/vendor/events';
import { eidProviders } from '@/constants';
import EidProviderButton from '@/components/shared/eidProviderButton';
import { countryToEIdProviderKey } from '@/vendor/utils/eId';

export default {
  components: { EidProviderButton },
  data() {
    return {
      loaded: false,
      initiating: false,
    };
  },
  computed: {
    /**
     * @return {EidProvider|null}
     */
    eidProvider() {
      if (
        !this.eidProviderKey ||
        !Object.keys(eidProviders).includes(this.eidProviderKey)
      ) {
        return null;
      }

      return eidProviders[this.eidProviderKey];
    },
    eidProviderKey() {
      return countryToEIdProviderKey(store.state.user.profile?.country);
    },
    userLanguage() {
      return store.state.user.profile?.language.toLowerCase() || 'en';
    },
    hasEidFeature() {
      return store.getters['user/HAS_FEATURE_FLAG']('Eid');
    },
    hasVerifiedEid() {
      return store.state.user.profile?.eid_verified_at;
    },
  },
  mounted() {
    EventBus.$on('eid-verification-sheet:open', this.open);
    EventBus.$on('eid-verification-sheet:close', this.dismiss);
  },
  beforeDestroy() {
    EventBus.$off('eid-verification-sheet:open', this.open);
  },
  methods: {
    open() {
      if (this.eidProvider === null || this.hasVerifiedEid) {
        return;
      }

      this.loaded = true;

      this.$nextTick(() => {
        this.$refs.sheet?.open();
      });
    },
    dismiss() {
      this.$refs.sheet?.dismiss();
    },
    initiateVerification() {
      if (this.initiating) {
        return;
      }

      this.initiating = true;

      axios.post('/me/verify-eid')
        .then((res) => {
          send.openURL(res.data.redirect_url);
        })
        .finally(() => {
          this.initiating = false;
        });
    },
  },
};
</script>

<style lang="scss">
.eid-logo {
  display: block;
  margin: 0 auto;
  max-width: 50vw;
  min-width: 8rem;
  max-height: 5.5rem;
}
.svg-button {
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}
</style>