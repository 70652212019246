<template>
  <div class="sheet-wrapper" ref="wrap"
    @mousemove="dragMoving"
    @touchmove="dragMoving"
    @mouseUp="dragFinish"
    @touchend="dragFinish"
    @click.self="dismiss"
    :class="[pointerEvents && isOpen ? 'pointer-events' : '']">
    <n-container ref="sheet" class="sheet" :class="[`type-${type}`]"
      v-on="dragBody ? {
        mousedown: dragStart,
        touchstart: dragStart
      } : {}"
      v-swipe:up="goUp"
      v-swipe:down="goDown"
      v-show="visible">
      <div :class="[type === 'header' ? 'header' : 'handlebar']"
        :style="hexColor ? { backgroundColor: hexColor } : {}"
        v-on="!dragBody ? {
          mousedown: dragStart,
          touchstart: dragStart
        } : {}">
        <n-layout v-if="type === 'header'" :spacing-y="false">
          <n-icon name="chevron-down" outline color="white" @click="dismiss" />
          <n-column :span="5"><n-text preset="title" :uppercase="titleUppercase">{{title}}</n-text></n-column>
        </n-layout>
        <n-layout v-else :spacing-y="false" :spacing-x="false">
          <div class="handlebar-lever"></div>
        </n-layout>
      </div>

      <n-layout v-if="hasTopSlot" :spacing-y="false" class="top">
        <slot name="top" />
      </n-layout>
      <div v-if="!loading" class="scroll" :class="{'disabled' : !scrollable, 'pad' : padBottom || padBottomExtra, 'extra': padBottomExtra}" @scroll="onScroll">
        <n-layout :spacing-x="!noLayout" :spacing-y="!noLayout" :class="{'fillHeight' : fillHeight}">
          <slot />
        </n-layout>
      </div>

      <div v-if="loading" class="loading-container">
        <n-spinner color="accent"/>
      </div>
    </n-container>
</div>
</template>
<script>
/**
 * The dissmised / type = header lofic could be rewritten
 * Maybe the same with isOpen and visible. Even though it might be different things.
 */
import { Swipe } from '@/directives/SwipeDirective.js';

const defaultTopDistance = 25;

export default {
  directives: {
    Swipe,
  },
  props: {
    snapPoints: {
      type: Array,
      default: () => []
    },
    top: {
      type: Number,
      default: defaultTopDistance,
    },
    bottom: {
      type: Number,
      default: 150,
    },
    start: {
      type: Number,
      default: defaultTopDistance,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "default",
      validator: (type) => ["default", "header"].includes(type),
    },
    title: {
      type: String,
      default: null,
    },
    titleUppercase: {
      type: Boolean,
      default: true
    },
    closed: {
      type: Boolean,
      default: false,
    },
    noLayout: {
      type: Boolean,
      default: false,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    dragBody: {
      type: Boolean,
      default: true,
    },
    padBottom: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    padBottomExtra: {
      type: Boolean,
      default: false,
    },
    hexColor: {
      type: String,
      default: null,
      validator: (color) => /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color),
    },
  },
  data() {
    return {
      visible: !this.dismissible && this.type !== 'header',
      isMoving: false,
      isSwiping: false,
      originalY: 0,
      handler: null,
      scrollable: false,
      scrolledToTop: true,
      pointerEvents: this.type === 'header',
      isOpen: !this.closed,
      timer: null,
    }
  },
  computed: {
    uiEdgeTop() {
      return this.$store.state.app.edges?.top;
    },
    maxPosition() {
      if (this.dismissible || this.type === 'header') {
        return window.innerHeight;
      }
      return this.bottom > this.startPosition ? this.bottom : this.startPosition;
    },
    topWithNotch() {
      return this.top === defaultTopDistance && this.uiEdgeTop ? this.uiEdgeTop : this.top;
    },
    startPosition() {
      return this.start === defaultTopDistance && this.uiEdgeTop ? this.uiEdgeTop : this.start;
    },
    hasTopSlot() {
      return !!this.$slots['top'];
    },
  },
  mounted() {
    this.handler = this.$refs.sheet.$el;
    this.isOpen = this.type === 'header' ? false : !this.closed;
    this.setPos(this.maxPosition);
    if (this.isOpen) {
      this.animateTo(this.startPosition)
    }
  },
  watch: {
    isMoving() {
      if (this.isMoving === false) {
        this.drop();
      }
    },
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scrolledToTop = scrollTop === 0;
    },
    dragStart(e) {
      if (!this.scrolledToTop && !this.isSwiping) return; // Don't drag if on top
      this.isMoving = true;
      this.originalY = (e.pageY||e.touches[0].pageY) - this.getPos();
    },
    dragMoving(e) {
      if (this.isMoving && !this.isSwiping) {
        let _y = (e.pageY||e.touches[0].pageY) - this.originalY;
        if (_y > this.topWithNotch && _y <= this.maxPosition) {
          this.setPos(_y);
        } else if( _y > this.maxPosition) {
          this.setPos(this.maxPosition);
        }
      }
    },
    dragFinish(e) {
      if (this.isMoving) {
        this.isMoving = false;
      }
    },
    /**
     * on swipe up
     */
    goUp() {
      this.isSwiping = true;
      const target = this.findTarget('up');
      this.animateTo(target);
    },
    /**
     * on swipe down
     */
    goDown() {
      this.isSwiping = true;
      const target = this.findTarget('down');
      this.animateTo(target);
    },
    dismiss() {
      this.isOpen = false;
      this.isSwiping = true;
      this.animateTo(this.maxPosition);
      this.$emit('dismissed');
    },
    open() {
      this.visible = true;
      this.isOpen = true;
      this.isSwiping = true;
      this.animateTo(this.startPosition);
    },
    /**
     * Dragging dropped, find snapping point
     */
    drop() {
      if (this.isSwiping === false) {
        const nextTarget = this.findTarget();
        this.animateTo(nextTarget)
      }
    },
    /**
     * Find the next snapping target
     * Set 'dir' (up/down) to only get snap in specific direction
     */
    findTarget(dir) {
      const current = this.getPos();
      let nextTarget = null;

      let points = [this.maxPosition, this.topWithNotch].concat(this.snapPoints);

      // remove points in opposit direction
      if (dir === 'up') {
        points = points.filter(p => p <= current);
      } else if (dir === 'down') {
        points = points.filter(p => p >= current);
      }

      nextTarget = points.reduce((prev, curr) => {
        if (!prev) {
          return curr;
        }

        return (Math.abs(curr - current) < Math.abs(prev - current) ? curr : prev);
      });

      return nextTarget;
    },
    /**
     * Moves the sheet to the target
     */
    animateTo(target) {
      const up = target < this.getPos();
      const velocity = up ? -30 : 30;

      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }

      this.timer = setInterval(() => {
        const newPos = this.getPos() + velocity;

        this.setPos(newPos);

        if ((up && this.getPos() <= target) ||
            (!up && this.getPos() >= target)) {
          this.setPos(target);
          this.isSwiping = false;
          clearInterval(this.timer);
        }
      }, 10);
    },
    getPos() {
      return parseInt(this.handler.style.top.replace('px', ''), 10);
    },
    setPos(pos) {
      this.scrollable = pos === this.topWithNotch;
      this.handler.style.top = pos + 'px';

      if (pos > this.maxPosition) {
        this.dismiss();
        if (this.dismissible || this.type === 'header') {
          this.visible = false;
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.sheet-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 11;
  pointer-events: none !important;
  &.pointer-events {
    pointer-events: all !important;
  }
  .sheet {
    pointer-events: all;
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: var(--edge-padding-bottom);
    &.type-header {
      border-radius: 20px 20px 0 0;
      .header {
        display: flex;
        align-items: center;
        height: 60px;
        border-radius: 20px 20px 0 0;
        background: var(--color-accent);
        color: var(--color-white);
        transition: background-color 0.2s ease-in-out,
          color 0.2s ease-in-out;
      }
    }
    .scroll {
      &.pad {
        padding-bottom: 25px;
        &.extra {
          padding-bottom: 100px;
        }
      }
      flex: 1;
      width: 100%;
      overflow-y: auto;
      overscroll-behavior-y: auto;
      -webkit-overflow-scrolling: touch;
      &.disabled {
        overflow: hidden;
      }
    }
  }
}

.handlebar {
  padding: 20px;
  &-lever {
    grid-column: 3 / 2 span;
    background-color: rgba(#c6c6c6, 0.25);
    border-radius: 9999px;
    height: 4px;
  }
}

.top {
  padding-top: var(--grid-padding-y);
}

.fillHeight {
  height: 100%;
}

.loading-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
}

</style>
