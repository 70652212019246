export const bookingStatus = {
  ACCEPTED: 'ACCEPTED',
  DENIED: 'DENIED',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  RESERVED: 'RESERVED',
  CANCELED: 'CANCELED',
};

export const commuteBookingStatus = {
  accepted: 'ACCEPTED',
  denied: 'DENIED',
  awaitingApproval: 'AWAITING_APPROVAL',
  canceled: 'CANCELED',
};

export const defaultSeats = 4;

export default {
  defaultSeats: defaultSeats,
  defaultTripTimes: {
    departure: {
      daysOffset: 1,
      hours: 8,
      minutes: 0,
    },
    return: {
      daysOffset: 1,
      hours: 16,
      minutes: 0,
    },
  },
  commuteTypes: {
    work: 'WORK',
    otherRecurring: 'OTHER',
    singleTrip: 'SINGLE_TRIP',
  },
  timeTypes: {
    departure: 'DEPARTURE',
    arrival: 'ARRIVAL',
  },
  demandDefaultReturnTime: {
    hours: 16,
    minutes: 0,
  },
  commuteBookingStatus,
  commuteRequestStatus: {
    open: 'OPEN',
    awaitingApproval: 'AWAITING_APPROVAL',
    booked: 'BOOKED',
    stalled: 'STALLED',
    publicTransport: 'PUBLIC_TRANSPORT',
    canceled: 'CANCELED',
  },
  denmarkLatLng: {
    lat: 56.2265414,
    lng: 10.4217051,
  },
  transportIcons: {
    IC: 'train',
    LYN: 'train',
    REG: 'train',
    S: 'train',
    TOG: 'train',
    TRAM: 'train',
    SUBWAY: 'train',
    BUS: 'bus',
    EXB: 'bus',
    NB: 'bus',
    TB: 'car',
    M: 'train',
    WALK: 'person-walking',
    TRAIN: 'train',
    CAR: 'car',
    FLEXTUR: 'car',
    JNY: 'bus', // Trafiklab does not have multiple atm,
    LET: 'train',
    LETBANE: 'train',
    FOOT: 'person-walking',
    METRO: 'train',
    RAIL: 'train',
    SUB: 'train',
    GEN: 'train',
    COMM: 'train',
  },
  weekdays: [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ],
  bookingStatus,
  tripTypes: {
    planned: 'PLANNED',
    onDemand: 'ON_DEMAND',
  },
  tripActivation: {
    gracePeriodMinutes: 15,
  },
  publicTransportProviderLogos: {
    REJSEPLANEN:
      process.env.VUE_APP_ROOT_URL +
      '/images/transportproviders/rejseplanen.png',
    ENTUR:
      process.env.VUE_APP_ROOT_URL + '/images/transportproviders/entur.png',
    TRAFIKLAB:
      process.env.VUE_APP_ROOT_URL + '/images/transportproviders/trafiklab.png',
    NEGENTWEE:
      process.env.VUE_APP_ROOT_URL + '/images/transportproviders/negentwee.png',
  },
};

export const permission = {
  PUSH: 'requestPushPermission',
  LOCATION: 'requestLocationPermission',
};

/**
 * Days to generate commute driver trips or commute requests
 * must match /app/Project/Commute/CommuteRecurringTrip.php (GENERATE_PERIOD)
 */
export const TRIP_GENERATION_DAYS = 7; // must match CommuteRecurringTrip::GENERATE_PERIOD

/**
 * @type {EidProviders}
 */
export const eidProviders = {
  dkmitid: {
    name: 'MitID',
    logo: 'ng-logo-mitid',
    sheetBackgroundColor: '#0060E6',
    titleColor: '#0060E6',
    textColor: '#4C4A41',
    button: {
      border: '1.5px solid #E3E1DE',
      backgroundColor: 'white',
      height: '41px',
      language: {
        en: require('@/assets/svg-buttons/mitid-button-en.svg'),
        da: require('@/assets/svg-buttons/mitid-button-da.svg'),
      },
    },
  },
  novippslogin: {
    name: 'Vipps',
    logo: 'ng-logo-vipps',
    sheetBackgroundColor: '#FF5B24',
    titleColor: '#FF5B24',
    textColor: '#4C4A41',
    button: {
      color: '#FF5B24',
      border: '1.5px solid #E3E1DE',
      backgroundColor: 'white',
      height: '44px',
      language: {
        en: require('@/assets/svg-buttons/vipps-button-en.svg'),
        nb: require('@/assets/svg-buttons/vipps-button-no.svg'),
      },
    },
  },
  sebankid: {
    name: 'BankID',
    logo: 'ng-logo-swbankid',
    sheetBackgroundColor: '#193E4F',
    titleColor: '#193E4F',
    textColor: '#4C4A41',
    button: {
      color: '#193E4F',
      border: '1.5px solid #E3E1DE',
      backgroundColor: 'white',
      height: '43px',
      language: {
        en: require('@/assets/svg-buttons/bankid-button-en.svg'),
        sv: require('@/assets/svg-buttons/bankid-button-se.svg'),
      }
    },
  },
  nlidin: {
    name: 'iDIN',
    logo: 'ng-logo-idin',
    sheetBackgroundColor: '#01B0F1',
    titleColor: '#3F3F3F',
    textColor: '#3F3F3F',
    button: {
      border: '3px solid #8A9B9C',
      backgroundColor: '#CBE4E5',
      height: '45px',
      language: {
        en: require('@/assets/svg-buttons/idin-button-en.svg'),
        nl: require('@/assets/svg-buttons/idin-button-nl.svg'),
      },
    },
  },
};


export const onDemandOptions = {
  locationLifetime: 45,
};
