import { EventBus } from '@/vendor/events';
import { triggerFormbricksAction } from '@/plugins/Formbricks';
import eventTypes from './event-types';

export const setupEventListeners = () => {
    EventBus.$on('trip-event', (event) => {
      const { action, type } = event;

      switch (action) {
        case eventTypes.TRIP_CREATED:
          triggerFormbricksAction(`Action:${type}Trip:Created`);
          break;

        case 'completed':
          triggerFormbricksAction(`Action:${type}Trip:Completed`);
          break;
      }
    });

    EventBus.$on('nav-bar-back', (route) => {
      if (route.name === 'main.provide') {
        triggerFormbricksAction(`Action:OfferRide:Back`);
      }
    });
}