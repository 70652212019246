<template>
  <div
    id="card"
    class="box"
    :style="{ 'border': eIdData.button.border, 'background-color': eIdData.button.backgroundColor }"
  >
    <n-text-model
      id="title"
      :style="{ 'color': eIdData.titleColor, 'font-size': '16px' }"
    >
      {{ $t('profile.eidVerificationBox.title') }}
    </n-text-model>
    <n-text
      preset="sub"
      class="sub-text"
      :style="{ 'color': eIdData.textColor }"
    >
      {{ $t('profile.eidVerificationBox.text', { eId: eIdData.name }) }}
    </n-text>
    <eid-provider-button
      :user-language="userLanguage"
      :e-id="eId"
      @click="$emit('click')"
    />
  </div>
</template>

<script>
import { eidProviders } from '@/constants';
import EidProviderButton from '@/components/shared/eidProviderButton';

export default {
  name: 'EIdVerification',
  components: { EidProviderButton },
  props: {
    eId: {
      type: String,
      required: true,
      validator: (eId) => Object.keys(eidProviders).includes(eId),
    },
  },
  data() {
    return {
      eIdData: eidProviders[this.eId],
    }
  },
  computed: {
    userLanguage() {
      return this.$store.state.user.profile?.language.toLowerCase() || 'en';
    },
  },
};
</script>

<style scoped lang="scss">
#title {
  font-weight: 500;
}

.box {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 2px;
  border: 1.5px solid #e3e1de;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
}

.sub-text {
  margin-block: 10px !important;
}

button {
  align-self: center;
}

.svg-button {
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}
</style>
