<template>
  <img
    :src="eIdData.button.language[buttonLanguage]"
    :alt="eId + ' button'"
    :height="eIdData.button.height"
    @click="handleClick"
  >
</template>

<script>
import { eidProviders } from '@/constants';

export default {
    name: 'EIdProviderButton',
    props: {
        eId: {
            type: String,
            required: true,
            validator: (eId) => Object.keys(eidProviders).includes(eId),
        },
        userLanguage: {
            type: String,
            required: true
        },
    },
    computed: {
        buttonLanguage() {
            const userLanguage = this.userLanguage.toLowerCase();
            return this.eIdData.button.language[userLanguage] ? userLanguage : 'en';
        },
        eIdData() {
            return eidProviders[this.eId];
        },
    },
    methods: {
        handleClick() {
            this.$emit('click');
        }
    },
}
</script>
