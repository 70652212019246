/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_account-star': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path d="M10.2973 2.63199L11.6173 5.27199C11.7973 5.63949 12.2773 5.99199 12.6823 6.05949L15.0748 6.45699C16.6048 6.71199 16.9648 7.82199 15.8623 8.91699L14.0023 10.777C13.6873 11.092 13.5148 11.6995 13.6123 12.1345L14.1448 14.437C14.5648 16.2595 13.5973 16.9645 11.9848 16.012L9.74231 14.6845C9.33731 14.4445 8.66981 14.4445 8.25731 14.6845L6.01481 16.012C4.40981 16.9645 3.43481 16.252 3.85481 14.437L4.38731 12.1345C4.48481 11.6995 4.31231 11.092 3.99731 10.777L2.13731 8.91699C1.04231 7.82199 1.39481 6.71199 2.92481 6.45699L5.31731 6.05949C5.71481 5.99199 6.19481 5.63949 6.37481 5.27199L7.69481 2.63199C8.41481 1.19949 9.58481 1.19949 10.2973 2.63199Z" fill="#FFD900"/>'
  }
})
