<template>
  <div class="rating-container">
    <n-icon
      v-bind="$props"
      v-for="i in 5"
      :key="i"
      :color="filled(i) && editable ? 'grey' : ''"
      :name="iconByIndex(i)"
      :outline="filled(i) && editable"
      class="rating-star feedback"
      :class="[{
        'faded': filled(i),
        'not-rated': (rating === 0 || locked) && editable,
        'orange': !filled(i) && rated,
        'yellow': !filled(i) && color === 'yellow'
      }, size]"
      @click="rate(i)"
    />
  </div>
</template>

<script>
  export default {
    props: {
      stars: {
        type: [Number, String],
        default: 0,
      },
      editable: {
        type: Boolean,
        default: false,
      },
      locked: {
        type: Boolean,
        default: true,
      },
      size: {
        type: String,
        default: 'md',
        validator: (size) => ["sm", "md", "lg"].includes(size),
      },
      rated: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: 'accent',
        validator: (color) => ["accent", "yellow", "orange"].includes(color),
      },
    },
    data() {
      return {
        rating: this.stars,
      };
    },
    watch: {
      stars(val) {
        this.rating = val;
      },
    },
    methods: {
      iconByIndex(i) {
        const diff = i - (Math.round(Number(this.rating) * 2) / 2);

        if (diff === 0.5) {
          return 'star-half';
        }

        return 'star';
      },
      filled(i) {
        return i > this.rating;
      },
      rate(index) {
        if (this.editable) {
          this.rating = index;
          this.$emit('input', this.rating);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/style/variables.scss';

  .rating-container {
    display: flex;
    flex-direction: row;
  }

  .rating-star {
    fill: var(--color-accent);
    width: 1.2rem;
    height: 1.2rem;
    
    &.faded {
      fill: var(--color-grey); // used in star-half
    }
    &.not-rated {
      fill: var(--color-white);
    }

    &.orange {
      fill: var(--color-orange);
    }
    &.yellow {
      fill: #FFD900;
    }
    &.sm {
      width: 0.8rem;
      height: 0.8rem;
    }
    &.lg {
      width: 1.7rem;
      height: 1.7rem;
      margin-right: 0.5rem;
    }
  }
</style>

