/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_checkmark_rounded': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<circle cx="7.91394" cy="7.99988" r="7.91394"/><line x1="3.63627" y1="7.87728" x2="6.40339" y2="10.6444" stroke="#F8F8F7" stroke-width="1.8"/><line x1="13.0695" y1="4.69639" x2="6.42846" y2="11.8909" stroke="#F8F8F7" stroke-width="1.8"/>'  
  }
})
