import store from '@/store';
import Hotjar from '@hotjar/browser';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';

let hotJarInitiated = false;

export const initHotjar = (userdata) => {
  if (typeof window === 'undefined' || hotJarInitiated) {
    return;
  }

  if (!process.env.VUE_APP_HOTJAR_ID) {
    console.warn('No environment set up for Hotjar');
    return;
  }

  const user = store.state.user.profile;
  if (!user.id) {
    console.warn('No user provided for Hotjar');
    return;
  }

  Hotjar.init(process.env.VUE_APP_HOTJAR_ID, 6, {
    debug: process.env.NODE_ENV !== 'production',
  });

  const { id } = userdata;
  const attributes = store.getters[userTypes.GET_USER_TRACKING_ATTRIBUTES];

  hotJarInitiated = true;

  Hotjar.identify(id, attributes);

};