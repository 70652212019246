<template>
  <div
    class="icon-wrapper"
    :class="[inline ? 'inline' : '']"
  >
    <div
      class="icon" 
      :class="[
        !customSize ? `size-${size}` : '', 
        `${cssType}`, 
        `color-${color}`, 
        outline ? 'outline' : ''
      ]
      "
      :style="customProps"
    >
      <svg-icon
        :name="`n_${name}`"
        v-on="$listeners"
      />
    </div>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "md",
      validator: (size) => ["xs", "sm", "md", "lg", "xl", "xxl"].includes(size),
    },
    customSize: {
      type: String,
      default: null,
      validator: (size) => !isNaN(size),
    },
    type: {
      type: String,
      default: "plain",
      validator: (type) => ["plain", "circle", "circle-inverse"].includes(type)
    },
    outline: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "default",
      validator: (color) => ["default", "error", "muted", "accent", "white", 'grey', 'grey-dark', 'grey-darker', '', 'accent-faded', 'warning', 'inherit', 'light-gray', 'green-dark', 'close-gray'].includes(color)
    },
    inline: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      focused: false,
    }
  },
  computed: {
    cssType() {
      return this.type === 'circle-inverse' ? 'circle inverse' : this.type;
    },
    customProps () {
      if (!this.customSize) {
        return {};
      }

      return {
        'width': this.customSize ? this.customSize + 'px' : 'auto',
        'height': this.customSize ? this.customSize + 'px' : 'auto',
      }
    }
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.icon-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.inline {
    display: inline;
    .icon {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.icon{
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // --color: var(--color-grey-darker);
    &.color-white {
      --color: var(--color-white);
    }
    &.color-accent {
      --color: var(--color-accent);
    }
    &.color-accent-faded {
      --color: var(--color-accent-faded);
    }
    &.color-muted {
      --color: var(--color-grey-light);
    }
    &.color-error {
      --color: var(--color-red);
    }
    &.color-grey {
      --color: var(--color-grey);
    }
    &.color-grey-dark {
      --color: var(--color-grey-dark);
    }
    &.color-grey-darker {
      --color: var(--color-grey-darker);
    }
    &.color-default {
      --color: var(--color-grey-darker);
    }
    &.color-warning {
      --color: var(--color-orange);
    }
    &.color-light-gray {
      --color: var(--color-grey-light);
    }
    &.color-green-dark {
      --color: var(--color-green-dark);
    }




    // Specific color is used for a close button (modal).
    &.color-close-gray {
      --color: #CDCDCD;
    }

  svg {
    height: 100%;
    width: 100%;
    fill: var(--color);
  }

  &.outline svg {
    fill: transparent;
    stroke: var(--color);
  }

  &.circle {
    background: var(--color);
    box-sizing: border-box;
    border: solid 1px var(--color);
    svg {
      fill: var(--color-white);
    }
    &.outline svg {
      fill: var(--color);
      stroke: var(--color-white);
    }
  }

  &.circle.inverse {
    background: var(--color-white);
    svg {
      fill: var(--color);
    }
    &.outline svg {
      fill: var(--color-white);
      stroke: var(--color);
    }
  }

  &.size-xs {
    height: var(--icon-xs-size);
    width: var(--icon-xs-size);
    &.circle {
      padding: 0px;
    }
  }
  &.size-sm {
    height: var(--icon-sm-size);
    width: var(--icon-sm-size);
    &.circle {
      padding: 1px;
    }
  }
  &.size-md {
    height: var(--icon-md-size);
    width: var(--icon-md-size);
    &.circle {
      padding: 2px;
    }
  }
  &.size-lg {
    height: var(--icon-lg-size);
    width: var(--icon-lg-size);
    &.circle {
      padding: 7px;
    }
  }
  &.size-xl {
    height: var(--icon-xl-size);
    width: var(--icon-xl-size);
    &.circle {
      padding: 10px;
    }
  }
  &.size-xxl {
    height: var(--icon-xxl-size);
    width: var(--icon-xxl-size);
    &.circle {
      background: var(--color-grey-light);
      border: none;
      padding: 8px;
      svg {
        fill: var(--color);
      }
    }
  }
}

</style>
