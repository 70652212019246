/* eslint-disable import/prefer-default-export */

import store from '@/store';
import { format } from '@/vendor/date-fns';
import semverLte from 'semver/functions/lte';
import { addMinutes, subHours, isWithinInterval } from 'date-fns';


export function createTypes(types) {
  const res = {};

  types.forEach((key) => {
    res[key] = key;
  });

  return res;
}

export function createTypesNamespaced(namespace, types) {
  const res = {};

  types.forEach((key) => {
    res[key] = `${namespace}/${key}`;
  });

  return res;
}

export function reduceWithoutKeys(obj, excluded, prefix = '') {
  const target = {};
  const keys = Object.keys(obj);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (excluded.indexOf(`${prefix}${key}`) !== -1) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (obj[key] instanceof Date) {
      target[key] = JSON.stringify(obj[key]);

      // eslint-disable-next-line no-continue
      continue;
    }

    if (typeof obj[key] === 'object' && obj[key] !== null && Array.isArray(obj[key]) === false) {
      target[key] = reduceWithoutKeys(obj[key], excluded, `${key}.`);
    } else {
      target[key] = obj[key];
    }
  }

  return target;
}

export function shortName(name) {
  return name
    .split(' ')
    .map((val, i) => {
      val = val.trim();

      if (i === 0) {
        return `${val.substr(0, 1).toUpperCase()}${val.substr(1)}`;
      } if (i === 1) {
        return `${val.substr(0, 1).toUpperCase()}.`;
      }

      return '';
    })
    .filter((val) => val !== '')
    .join(' ');
}

export function formatAddressComponent(str) {
  return str.replace(/[\s,]{0,2}null[\s,]{0,2}/g, '').trim();
}

export function formatAddressComponents({
  street, zipcode, city, country,
}) {
  const components = [
    street,
    `${zipcode || ''} ${city || ''}`,
    country,
  ];

  return components
    .filter((c) => (typeof c === 'string'))
    .map((c) => formatAddressComponent(c))
    .filter((c) => (c.length > 0 && c.indexOf('null') === -1))
    .join(', ');
}

export function getAddressComponent(component, addressComponents) {
  const results = addressComponents.filter((a) => a.types.indexOf(component) !== -1);
  if (component === 'country') return results.length ? results[0].short_name : null;

  return results.length ? results[0].long_name : null;
}

/**
 * @returns {Address} address object for request
 */
export function getAllAddressComponents(addressComponents) {
  const streetName = getAddressComponent('route', addressComponents.address_components);
  const streetNumber = getAddressComponent('street_number', addressComponents.address_components);

  let street = formatAddressComponent(`${streetName} ${streetNumber}`);
  if (!street) {
    if (addressComponents.structured_formatting) {
      street = addressComponents.structured_formatting.main_text;
    } else if(addressComponents.description) {
      street = addressComponents.description.substr(0, addressComponents.description.indexOf(','));
    } else {
      street = addressComponents.formatted_address.substr(0, addressComponents.formatted_address.indexOf(','));
    }
  }

  return {
    street,
    zipcode: getAddressComponent('postal_code', addressComponents.address_components),
    city: cityFromComponent(addressComponents.address_components),
    country: getAddressComponent('country', addressComponents.address_components),
    lat: addressComponents.geometry.location.lat,
    lng: addressComponents.geometry.location.lng,
  };
}

function cityFromComponent(cpt) {
  let city = getAddressComponent('sublocality_level_1', cpt);

  if (!city) {
    city = getAddressComponent('locality', cpt);
  }

  // Mostly Sweden
  if (!city) {
    city = getAddressComponent('postal_town', cpt);
  }

  // Fallback
  if (!city) {
    city = getAddressComponent('postal_code', cpt);
  }
  return city;
}

export function setAllAddressComponents(prefix, data, addressComponents) {
  const components = getAllAddressComponents(addressComponents);
  const keys = Object.keys(components);

  for (let i = 0; i < keys.length; i++) {
    data[prefix + keys[i]] = components[keys[i]];
  }
}

/**
 * @param {AddressTransformed} address
 * @returns {boolean}
 */
export function isValidAddress(address) {
  return address?.street && address?.zipcode && address?.country;
}

export function isSameAddress(a, b) {
  if (!a || !b || !a.lat || !a.lng || !b.lat || !b.lng) {
    return false;
  }

  const precision = Math.pow(10, 6); // roughly 0.1 meters

  return (
    (Math.round(a.lat * precision) / precision) == (Math.round(b.lat * precision) / precision) &&
    (Math.round(a.lng * precision) / precision) == (Math.round(b.lng * precision) / precision)
  );
}

/**
 * @param {AddressTransformed} address
 */
export function formatTransformedAddress(address) {
  const components = [];

  if (address.street) {
    components.push(address.street);
  }

  if (address.zipcode && address.city) {
    components.push(`${address.zipcode} ${address.city}`);
  }

  return components
    .map((component) => String(component).trim())
    .filter((component) => Boolean(component))
    .join(', ');
}

export function objectToFormData(data, options) {
  const fd = new FormData();
  const keys = Object.keys(data);

  const optional = options.optional ? options.optional : [];

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = data[key];

    if (typeof optional === 'boolean' && optional === true && value === null) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (typeof optional === 'object' && optional.indexOf(key) !== -1 && value === null) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
      const attKeys = Object.keys(value);

      for (let n = 0; n < attKeys.length; n++) {
        const attKey = attKeys[n];
        const attValue = value[attKey];

        // TODO: the following should be refactored

        if (attValue === null) {
          fd.append(`${key}[${attKey}]`, '');
        } else if (attValue instanceof File) {
          fd.append(`${key}[${attKey}]`, attValue);
        } else if (typeof attValue === 'object') {
          const attValueKeys = Object.keys(attValue);

          for (let k = 0; k < attValueKeys.length; k++) {
            const attValueKey = attValueKeys[k];
            const attValueValue = attValue[attValueKey];

            if (typeof attValueValue === 'object' && attValueValue !== null && !(attValueValue instanceof Array) && !(attValueValue instanceof File)) {
              const attValueValueKeys = Object.keys(attValueValue);

              for (let j = 0; j < attValueValueKeys.length; j++) {
                const attValueValueKey = attValueValueKeys[j];
                const attValueValueValue = attValueValue[attValueValueKey];

                if (attValueValueKey === 'types') {
                  fd.append(`${key}[${attKey}][${attValueKey}][${attValueValueKey}][0]`, attValueValueValue[0]);
                } else {
                  fd.append(`${key}[${attKey}][${attValueKey}][${attValueValueKey}]`, attValueValueValue);
                }
              }
            } else {
              fd.append(`${key}[${attKey}][${attValueKey}]`, attValueValue);
            }
          }
        } else {
          fd.append(`${key}[${attKey}]`, attValue);
        }
      }
    } else {
      fd.append(key, value);
    }
  }

  return fd;
}

export function adaptMapsStep(step) {
  return ({
    distance: step.distance.value,
    duration: step.duration.value,
    // start_location: step.start_location,
    // end_location: step.end_location,
    polyline: step.polyline,
  });
}

export function parseWaypoints(waypoints) {
  if (!waypoints || waypoints.length === 0) {
    return null;
  }

  return waypoints
    .map((w) => w.coords)
    .filter((c) => c !== null);
}
/**
 * @returns {Address}
 */
export function extractLocationData(obj, prefix) {
  const props = ['lat', 'lng', 'street', 'zipcode', 'city', 'country', 'place_id'];
  let newObj = {};
  props.forEach(x => {
    newObj[`${prefix}${x}`] = obj[x];
  })
  return newObj;
}

export function parseTripDataV4(rawData, provide = true) {
  const rawStart = rawData.fromAddress;
  const rawEnd = rawData.toAddress;

  let data = {
    departure: rawData.plannedTime ? format(rawData.plannedTime) : format(new Date()), // kept for backwards compatibility (October 2024)
    planned_time: rawData.plannedTime ? format(rawData.plannedTime) : format(new Date()),
    planned_time_by: rawData.plannedTimeBy?.toUpperCase() ?? 'DEPARTURE',
    charge_for_trip: rawData.chargeForTrip
  };

  if (rawData.seats) {
    data.seats = rawData.seats.id ?? rawData.seats;
  }

  // setAll... when data is from a search (map), extract, if it was on a trip
  // when this can both prepare an object for creation and for updating where locations may or may not be changed
  data.type = rawData.commuteType;
  if (rawStart.placeObject) {
    setAllAddressComponents('from_', data, rawStart.placeObject);
  } else if (rawStart?.parts?.country) {
    data = {
      ...extractLocationData(rawStart.parts, 'from_'),
      ...data
    };
  } else {
    data = {
      ...extractLocationData(rawStart, 'from_'),
      ...data
    };
  }

  if (typeof rawStart.stop === 'object') {
    data.from_stop_id = rawStart.stop.id;
  }
  if (rawEnd.placeObject) {
    setAllAddressComponents('to_', data, rawEnd.placeObject);
  } else if (rawEnd?.parts?.country) {
    data = {
      ...extractLocationData(rawEnd.parts, 'to_'),
      ...data
    };
  } else {
    data = {...extractLocationData(rawEnd, 'to_'), ...data };
  }
  if (typeof rawEnd.stop === 'object') {
    data.to_stop_id = rawEnd.stop.id;
  }
  if (provide && rawData.selectedDepartureRoute && rawData.selectedDepartureRoute.overview_polyline) { // has selectedDepartureRoute id data from maps (ie not a edit without address re select)
    data.departure_route = rawData.selectedDepartureRoute.overview_polyline.points;
    data.departure_route_steps = rawData.selectedDepartureRoute.legs[0].steps.map(s => adaptMapsStep(s));
    data.departure_route_waypoints = parseWaypoints(rawData.selectedDepartureRoute.waypoints);
    data.departure_distance = rawData.selectedDepartureRoute.legs[0].distance.value;
    data.departure_duration = rawData.selectedDepartureRoute.legs[0].duration.value;
  }
  if (provide && (data.departure_route || rawData.selectedDepartureStops)) {
    data.departure_stops = rawData.selectedDepartureStops;
  }
  if (provide && typeof rawData.selectedViaStops === 'object') {
    data.via_stops = rawData.selectedViaStops;
  }

  return data;
}

export function parseMapsToLocation(maps) {
  if (maps.placeObject != null) return maps; // Already converted
  let parts = {};
  let data = {
    lat: maps.geometry.location.lat,          // For the frontend
    lng: maps.geometry.location.lng,          // For the frontend
    completeAddress: maps.formatted_address,  // For the frontend
    placeObject: {...maps},                   // To have all the data
  }
  setAllAddressComponents('_', parts, maps); // For the backend
  data.parts = parts;
  return data;
}

/**
 * @param {AddressTransformed} address
 */
export function parseTransformedAddressToDeprecatedLocationFormat(address) {
  return {
    lat: address.coordinate.lat,
    lng: address.coordinate.lng,
    completeAddress: formatTransformedAddress(address),
    placeObject: null,
    transformed: true,
    parts: {
      ...address,
      lat: address.coordinate.lat,
      lng: address.coordinate.lng,
    },
  }
}

/**
 * When we have an location object, without the from/to departure/arrival prefix
 * When it's known if its origin or destination, set the prefix. Eg. just before the API call
 * @param {*} object
 */
export function prefixKeys(orgObj, prefix) {
  let obj = JSON.parse(JSON.stringify(orgObj));
  Object.keys(obj).forEach(key => {
    let originalKey = key.split('_')[1];
    if (!originalKey) originalKey = key;
    const newKey = `${prefix}_${originalKey}`;
    Object.defineProperty(obj, newKey, Object.getOwnPropertyDescriptor(obj, key));
    delete obj[key];
  });
  return obj;
}

export function unprefixKeys(orgObj, prefix) {
  let obj = JSON.parse(JSON.stringify(orgObj));
  Object.keys(obj).forEach(key => {
    const newKey = key.indexOf(prefix) === 0 ? key.substring(prefix.length) : key;
    Object.defineProperty(obj, newKey, Object.getOwnPropertyDescriptor(obj, key));
    delete obj[key];
  });
  return obj;
}

/**
 * Parse a trip address to a location, for either departure or destination
 * address. Use 'from_' or 'to_' in prefix, for either of those addresses.
 *
 * @param {object} trip
 * @param {object} prefix
 * @returns object
 */
export function parseTripAddressToLocation(trip, prefix = '') {
  const completeAddress = trip[prefix + 'street'] && trip[prefix + 'city'] ?
    `${trip[prefix + 'street']} ${trip[prefix + 'city']}` :
    '';

  let lat = trip[`${prefix}lat`];
  let lng = trip[`${prefix}lng`];

  if (Array.isArray(trip.coordinate)) {
    [lat, lng] = trip.coordinate;
  } else if (typeof trip.coordinate === 'object') {
    lat = trip.coordinate.lat;
    lng = trip.coordinate.lng;
  }

  return {
    lat,
    lng,
    street: trip[`${prefix}street`],
    city: trip[`${prefix}city`],
    zipcode: trip[`${prefix}zipcode`],
    country: trip[`${prefix}country`],
    completeAddress,
  };
}

/**
 * Parse a trip to location objects, for easier and more generic access to
 * database fields in the app code base.
 *
 * @param {object} trip
 * @returns object
 */
export function parseTripToLocation(trip) {
  return {
    fromAddress: parseTripAddressToLocation(trip, 'from_'),
    toAddress: parseTripAddressToLocation(trip, 'to_'),
  };
}

export function parseRecurringOptions(rawData) {
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const weekdays = {};
  const type = 'leave'; // Kept for db

  rawData.times.forEach(x => {
    weekdays[`${x.id}_${type}`] =  format(x.date, 'HH:mm');
  });

  // Fill non selected days with null
  days.forEach(x => {
    const key = `${x}_${type}`;
    if (weekdays[key] == null) {
      weekdays[key] = null;
    }
  });
  return {
    ...weekdays,
  }
}

/**
 *
 * @param {string} planned_departure - format 2024-02-26T08:15:29.000000Z
 * @param {number?} tripDuration
 * @returns
 */
export function canStartTrip(planned_departure, tripDurationInMinutes = null) {
  if (!tripDurationInMinutes) {
    tripDurationInMinutes = 0;
  }
  const plannedStart = new Date(planned_departure);
  const fourHoursBefore = subHours(plannedStart, 4);
  const twentyMinutesAfter = addMinutes(plannedStart, 20);
  const tripEnd = addMinutes(plannedStart, tripDurationInMinutes);
  const end = tripEnd < twentyMinutesAfter ? twentyMinutesAfter : tripEnd;
  return isWithinInterval(new Date(), { start: fourHoursBefore, end: end});
}

export function swapAddressData(address)
{
  return {
    from_street: address.to_street,
    from_zipcode: address.to_zipcode,
    from_city: address.to_city,
    from_country: address.to_country,
    from_lat: address.to_lat,
    from_lng: address.to_lng,
    from_place_id: address.to_place_id,
    to_street: address.from_street,
    to_zipcode: address.from_zipcode,
    to_city: address.from_city,
    to_country: address.from_country,
    to_lat: address.from_lat,
    to_lng: address.from_lng,
    to_place_id: address.from_place_id,
  };
}

export function camelCaseToKebabCase(string)
{
  return string
    .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
    .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
    .toLowerCase();
}

export function isNullOrEmpty(string) {
  return string == null || string.trim() === '';
}

export function currencyCodeToSymbol(code) {
  switch(code) {
    case 'DKK':
      return 'kr.';
    case 'SEK':
      return 'kr.';
    case 'EUR':
      return '€';
    case 'NOK':
      return 'kr.';
  }
}

/**
 * Get formated trip prices with currency symbols.
 * @param {CommuteDriverTrip} trip
 * @returns {Object}
 */
export function formatPrice(trip) {
  const currency = `${currencyCodeToSymbol(trip.price_currency)}`;
  return {
    price: `${trip.price} ${currency}`,
    discounted_price: trip.price !== trip.actual_price ? `${trip.actual_price} ${currency}` : null
  }
}

export function headerIncludesAppId() {
    const identifier = 'com.nabogo';

    return ( typeof window !== 'undefined' && window.navigator.userAgent.includes(identifier));
}

export function isIosApp() {
  return typeof webkit !== 'undefined' && headerIncludesAppId();
}

export function isAndroidApp() {
  return typeof nativeObj === 'object' && headerIncludesAppId();
}

export function isBrowser() {
  return !headerIncludesAppId();
}

/**
 * @typedef {Object} AppVersion
 * @property {string} name
 * @property {int} build
 * @returns AppVersion
 */
export function getAppVersion() {
  const regex = /com.nabogo.*\/(\d+\.\d+\.\d+).*build (\d+)/g;
  const groups = [...window.navigator.userAgent.matchAll(regex)];

  if (groups.length === 0) {
    console.error('Useragent missing')
    return {
      name: 'missing version',
      build: 0,
    };
  }

  return {
    name: groups[0][1],
    build: parseInt(groups[0][2]),
  }
}

/**
 * @returns string
 */
export function getReleaseVersion() {
    return process.env.VUE_APP_VERSION || 'unknown';
}

export function isMinimumNativeVersion() {
  const minimumVersion = isIosApp() ? process.env.VUE_APP_IOS_MINIMUM_VERSION : process.env.VUE_APP_ANDROID_MINIMUM_VERSION;
  const currentVersion = getAppVersion().name;

  if (currentVersion === 'missing version') {
    return false;
  }

  return semverLte(minimumVersion, currentVersion);
}


export function contains9292Trips(trips) {
  if (Array.isArray(trips)) {
      return trips.some(trip => typeof trip.link === 'string' && trip.link.includes('9292.nl'))
  }
  if (typeof trips === 'object') {
    return contains9292Trips([trips]);
  }
  return false;
}

/**
 * @param {{ lat: number, lng: number} | { latitude: number, longitude: number}} location
 * @returns {{ lat: number, lng: number} | null}
 */
export function sanitizedLocation(location) {
  const shortLat = Number(location?.lat);
  const shortLng = Number(location?.lng);

  if (!isNaN(shortLat) && !isNaN(shortLng)) {
    return {
      lat: shortLat,
      lng: shortLng
    };
  }

  const longLat = Number(location?.latitude);
  const longLng = Number(location?.longitude);

  if (!isNaN(longLat) && !isNaN(longLng)) {
    return {
      lat: longLat,
      lng: longLng
    };
  }

  return null;
}

/**
 * @param {Object} obj
 */
export function removeUnderscorePrefixFromKeys(obj) {
  const newObj = {};
  for (const key in obj) {
    if (key.startsWith("_")) {
      const newKey = key.replace(/^_/, '');
      newObj[newKey] = obj[key];
    } else {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

export function getDeviceFingerprint() {
  return store.state.app.fingerprint;
}


/**
 * Caluclates and formats the trip type
 * @param {CommuteDriverTrip} trip
 * @returns string
 */
export function determineTripType(trip) {
  if (trip?.recurring_trip_id) return 'Recurring';
  if (trip.type === 'ON_DEMAND') return 'OnDemand';
  return 'Single';
}