<template>
  <div class="drawer-wrapper">
    <transition name="slide">
      <div
        v-show="isOpen"
        class="drawer"
      >
        <n-layout>
          <n-grid>
            <n-text
              preset="title"
              color="accent"
              class="span-5"
              @click="close"
            >
              {{
                $t('main.nav.title')
              }}
            </n-text>
            <n-icon
              name="cross"
              color="accent"
              outline
              class="flex-end feedback"
              @click="close"
            />
          </n-grid>
          <div class="span-6 scrollable">
            <n-grid
              :y-gap="5"
              @click.native="to('profile')"
            >
              <profile-card
                v-bind="profile"
                :has-eid-enabled="hasEidEnabled"
              />
            </n-grid>
            <n-grid class="menu-items">
              <div
                class="menu-item feedback"
                @click="to('profile')"
              >
                <n-text preset="title">
                  {{ $t('main.nav.profile') }}
                </n-text>
              </div>
              <span
                :class="{
                  notice: notifications.some((x) => x.type.includes('message')),
                }"
              />
              <div
                class="span-5 feedback"
                @click="to('conversations')"
              >
                <n-text
                  preset="title"
                  @click="to('conversations')"
                >
                  {{
                    $t('main.nav.messages')
                  }}
                </n-text>
              </div>
              <span
                :class="{
                  notice: notifications.some((x) => !x.type.includes('message')),
                }"
              />
              <div
                class="span-5 feedback"
                @click="to('notifications')"
              >
                <n-text
                  preset="title"
                  @click="to('notifications')"
                >
                  {{
                    $t('main.nav.notifications')
                  }}
                </n-text>
              </div>
              <div
                class="menu-item feedback"
                @click="to('trip-history')"
              >
                <n-text preset="title">
                  {{ $t('main.nav.tripHistory') }}
                </n-text>
              </div>
              <div
                class="menu-item feedback"
                @click="to('account')"
              >
                <n-text preset="title">
                  {{ $t('main.nav.myAccount') }}
                </n-text>
              </div>
              <span
                :class="{
                  'active-vacation': !!$store.getters['user/activeVacation'],
                }"
              />
              <div
                class="menu-item feedback"
                @click="to('vacation')"
              >
                <n-text preset="title">
                  {{ $t('main.nav.vacation') }}
                </n-text>
              </div>
              <div
                class="menu-item feedback"
                @click="showHowto"
              >
                <n-text preset="title">
                  {{ $t('main.nav.howTo') }}
                </n-text>
              </div>
              <a
                :href="$t('main.nav.helpLink')"
                target="_blank"
                class="menu-item feedback"
              ><n-text preset="title">{{ $t('main.nav.help') }}</n-text>
              </a>
            </n-grid>
          </div>
        </n-layout>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-show="isOpen"
        class="backdrop"
        @click="close"
      />
    </transition>
  </div>
</template>

<script>
import { EventBus } from '@/vendor/events';
import { mapState } from 'vuex';
import {
  namespace as namespaceUser,
  namespacedTypes as namespacedUser,
} from '@/store/modules/user-types';
import ProfileCard from '@/components/shared/profileCard';
import { namespace as userNamespace, namespacedTypes as userTypes } from '@/store/modules/user-types';

export default {
  name: 'MainStartNavDrawer',
  components: { ProfileCard },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState(namespaceUser, ['profile', 'notifications']),
    ratings_average() {
      return Math.round(this.profile.ratings_average);
    },
    hasEidEnabled() {
      return this.$store.getters[userTypes.HAS_FEATURE_FLAG]('Eid');
    },
  },
  mounted() {
    EventBus.$on('toggle-drawer', this.toggle);
    EventBus.$on('open-drawer', this.open);
    EventBus.$on('close-drawer', this.close);
  },
  beforeDestroy() {
    EventBus.$off('toggle-drawer', this.toggle);
    EventBus.$off('open-drawer', this.open);
    EventBus.$off('close-drawer', this.close);
  },
  methods: {
    to(dest) {
      this.close();

      setTimeout(() => {
        this.$router.push({ name: dest });
      }, 300);
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    showHowto() {
      this.close();

      setTimeout(() => {
        EventBus.$emit('show-howto');
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-wrapper {
  pointer-events: none !important;
}

.drawer {
  top: 20px;
  left: 20px;
  z-index: 998;
  position: fixed;
  max-height: initial;
  pointer-events: all;
  border-radius: 34px 0 0 0;
  width: calc(100vw - 20px);
  background: var(--color-white);
  top: calc(var(--edge-padding-top) + 14px);
  height: calc(100vh - var(--edge-padding-top));

  ::-webkit-scrollbar {
    display: none;
  }

  .menu-items {
    > * {
      padding: 10px 0;
    }
    .menu-item {
      grid-column: 2 / span 5;
    }
  }

  .scrollable {
    /* - parent spacing - outer spacing - header height - grid padding */
    height: calc(100vh - 10px - 10px - 25px - 30px);
    overflow: auto;
  }

  .active-vacation {
    height: 13px;
    width: 13px;
    display: flex;
    justify-self: flex-end;
    align-self: center;
    border-radius: 100%;
    background: var(--color-orange);
    padding: 0;
  }

  .notice {
    height: 13px;
    width: 13px;
    display: flex;
    justify-self: flex-end;
    align-self: center;
    border-radius: 100%;
    background: var(--color-red);
    padding: 0;
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.backdrop {
    top: 0;
    left: 0;
    opacity: 0.25;
    width: 100vw;
    z-index: 997;
    height: 100vh;
    position: fixed;
    background-color: black;
  }

// slide animation
.slide-leave-active,
.slide-enter-active {
  transition-timing-function: ease-in-out;
  transition: transform 0.25s;
}
.slide-enter,
.slide-leave-to {
  transform: translate(100%, 0);
}

// fade animation
.fade-enter-active,
.fade-leave-active {
  transition-timing-function: ease-in-out;
  transition: 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.profile-picture {
  margin-top: 0.25rem;
}

.profile-preview {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .profile-info {
    padding-right: 0.25rem;
  }

  .profile-stats {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}
</style>
