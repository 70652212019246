//@ts-check
import { eidProviders } from '@/constants'
import config from '@shared/config.json';

/**
 * @param {string} country
 * @returns {EidProvider}
 */
export function countryToEId(country) {
    switch (country) {
        case 'NL':
            return eidProviders.nlidin;
        case 'NO':
            return eidProviders.novippslogin;
        case 'SE':
            return eidProviders.sebankid;
        default:
            return eidProviders.dkmitid;
    }

}

/**
 * @param {string} country
 * @returns {string}
 */
export function countryToEIdProviderKey(country) {
    const eidKey = config.country_eid_providers[country];

    if (!eidKey) {
        console.error(`No '${country}' found in config.country_eid_providers`);
    }

    return eidKey ?? config.country_eid_providers["DK"];
}
