<template>
  <div class="bottom-safe-spacer" :style="styles">
    <div class="bottom-safe-spacer-inner">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BottomSafeSpacer',
  props: {
    min: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('app', ['edges']),
    edgePaddingBottom() {
      if (this.edges?.bottom && this.edges?.bottom < this.min) {
        return this.min + 'px';
      }

      return this.edges?.bottom + 'px';
    },
    styles() {
      return {
        'padding-bottom': this.edgePaddingBottom,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-safe-spacer {
  width: 100%;

  &-inner {
    position: relative;
  }
}
</style>
