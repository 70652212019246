<template>
  <div class="span-6 public-profile-container">
    <div class="public-profile-container__image-container">
      <profile-picture
        :src="image"
        size="lg"
        class="profile-picture"
      />
      <n-text-model
        size="md"
        align="center"
        class="public-profile-container__image-container-name"
      >
        <span>
          {{ fullName }}
        </span>
      </n-text-model>
    </div>
    <div class="public-profile-container__information">
      <n-text-model
        v-if="eid_verified"
        size="sm"
        weight="medium"
        color="green-darker"
        class="public-profile-container__verified-container"
      >
        <n-icon
          name="checkmark_rounded"
          size="sm"
          color="accent"
        />
        {{ $t('profileCard.providerVerified', { provider: $t(`eIDs.${eid_provider}`) }) }}
      </n-text-model>

      <n-text-model
        size="sm"
        weight="medium"
        color="blue-800"
        class="public-profile-container__verified-container"
      >
        <n-theme type="driver">
          <n-icon
            name="checkmark_rounded"
            size="sm"
            color="accent"
          />
        </n-theme>
        {{ $t('profileCard.phoneVerified') }}
      </n-text-model>

      <n-text-model
        size="sm"
        weight="medium"
        color="grey"
      >
        {{ `${stats.carpools_completed} ${$t('profileCard.carpools')}` }}
      </n-text-model>
      <n-text-model
        size="sm"
        weight="medium"
        color="grey"
      >
        {{ `${ratings_count} ${$t('profileCard.ratings')}` }}
      </n-text-model>
      <n-text-model
        size="sm"
        weight="medium"
        color="grey"
        class="public-profile-container__rating-container"
      >
        <rating
          size="md"
          color="yellow"
          :stars="ratings_average"
        />
        {{ ratings_average }}
      </n-text-model>
    </div>
  </div>
</template>

<script>
import ProfilePicture from '@/components/shared/profilePicture';
import Rating from '@/components/shared/rating';

export default {
    components: {
        ProfilePicture,
        Rating
    },
    props: {
        image: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        stats: {
            type: Object,
            default: () => ({
                carpools_completed: 0,
            }),
        },
        // eslint-disable-next-line vue/prop-name-casing
        last_name: {
            type: String,
            default: '',
        },
        // eslint-disable-next-line vue/prop-name-casing
        ratings_average: {
            type: Number,
            default: 0,
        },
        // eslint-disable-next-line vue/prop-name-casing
        ratings_count: {
            type: Number,
            default: 0,
        },
        // eslint-disable-next-line vue/prop-name-casing
        eid_verified: {
            type: Boolean,
            default: false,
        },
        // eslint-disable-next-line vue/prop-name-casing
        eid_provider: {
            type: String,
            default: '',
        },
    },
    computed: {
        fullName() {
            return `${this.name} ${this.last_name}`;
        }
    }
}
</script>

<style lang="scss" scoped>
.public-profile-container {
    display: flex;
    justify-content: space-between;
    padding: 25px 16px;
    border-radius: 2px;
    border: 1.5px solid #E3E1DE;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);

    .public-profile-container__image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-shrink: 1;
        width: 125px;

        .public-profile-container__image-container-name {
            margin-top: 8px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 100%;
        }
    }

    .public-profile-container__information {
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        margin-top:10px;
        width: 160px;
        align-self: flex-start;
        overflow: hidden;
        color: #746F62 !important;

        .public-profile-container__verified-container {
            display: flex;
            align-items: center;

            .icon-wrapper {
                margin-right: 8px;
            }
        }

        .public-profile-container__rating-container {
            display: flex;
            align-items: center;

            .rating-container {
                margin-right: 5px;
            }
        }

        span {
            margin-bottom: 5px;
        }
    }
}
</style>